import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from '../constant';

const EditPost = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [post, setPost] = useState({
        title: '',
        content: '',
        image: '',
    });
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [file, setFile] = useState(null);

    // Fetch post data on component mount
    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            setErrorMessage('You are not logged in. Redirecting...');
            setTimeout(() => navigate('/login'), 3000);
            return;
        }

        axios
            .get(`${BASE_URL}/blogposts/${id}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setPost(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching post data:', error);
                setErrorMessage('Failed to load the post. Please try again.');
                setLoading(false);
            });
    }, [id, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPost({ ...post, [name]: value });
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPost({ ...post, image: selectedFile.name });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('title', post.title);
        formData.append('content', post.content);
        if (file) {
            formData.append('image', file);
        }

        try {
            const token = localStorage.getItem('auth_token');
            await axios.put(
                `${BASE_URL}/update_blog/${id}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Manually update the state with the latest values
            setPost({
                ...post,
                title: post.title,
                content: post.content,
                image: file ? file.name : post.image, // Use file name for image
            });

            // Navigate to the post listing page after updating
            navigate(`/admin/posts`);
        } catch (error) {
            console.error('Error updating post:', error);
            setErrorMessage('Failed to update the post. Please try again.');
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold text-center mb-8">Edit Blog Post</h1>

            {errorMessage && (
                <div className="bg-red-100 text-red-700 p-4 rounded-md mb-6">
                    {errorMessage}
                </div>
            )}

            {loading ? (
                <p>Loading...</p>
            ) : (
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label htmlFor="title" className="block text-lg font-semibold">Title</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={post.title}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-md"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="content" className="block text-lg font-semibold">Content</label>
                        <textarea
                            id="content"
                            name="content"
                            value={post.content}
                            onChange={handleChange}
                            rows="10"
                            className="w-full p-3 border border-gray-300 rounded-md"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="image" className="block text-lg font-semibold">Image (Optional)</label>
                        <input
                            type="file"
                            id="image"
                            name="image"
                            onChange={handleFileChange}
                            className="block w-full p-3 border border-gray-300 rounded-md"
                        />
                    </div>

                    <div className="flex justify-between">
                        <button type="submit" className="bg-blue-600 text-white p-3 rounded-md hover:bg-blue-500">Update Post</button>
                        <button
                            type="button"
                            onClick={() => navigate('/admin/posts')}
                            className="bg-gray-600 text-white p-3 rounded-md hover:bg-gray-500"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default EditPost;
