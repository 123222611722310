import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import BASE_URL from '../constant';

const BlogDetail = () => {
  const [post, setPost] = useState(null);
  const { id } = useParams(); // Get the 'id' from the URL

  useEffect(() => {
    // Fetch the blog post based on the ID from the API
    fetch(`${BASE_URL}/blogposts/${id}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch post');
        }
        return response.json();
      })
      .then((data) => {
        setPost(data);
      })
      .catch((error) => {
        console.error('Error fetching post data:', error);
      });
  }, [id]);

  if (!post) {
    return <p>Loading...</p>; // Display loading while data is fetched
  }

  return (
    <div className="p-8">
      <div className="max-w-4xl mx-auto">
        <Link to="/" className="text-blue-500 mb-4 inline-block">Back to all posts</Link>
        <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
        {post.image && (
          <img 
            src={post.image.startsWith('http') ? post.image : `/blog_images${post.image}`} 
            alt={post.title} 
            className="w-full h-64 object-cover mb-4" 
          />
        )}
        <p className="text-lg text-gray-800 mb-4">{post.content}</p>
        <p className="text-gray-500 text-sm">Posted on {new Date(post.created_at).toLocaleDateString()}</p>
      </div>
    </div>
  );
};

export default BlogDetail;
