import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BASE_URL from '../constant';

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Fetching blog posts from the API endpoint
    //fetch('http://127.0.0.1:8000/api/blogposts/')
       fetch(BASE_URL + '/blogposts/')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch');
        }
        return response.json();
      })
      .then((data) => {
        setPosts(data);
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }, []);

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold text-center mb-8">Blog Posts</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {posts.length === 0 ? (
          <p>No posts available.</p>
        ) : (
          posts.map((post) => (
            <div key={post.id} className="bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden">
              {post.image && (
                <img src={post.image} alt={post.title} className="w-full h-56 object-cover" />
              )}
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800">{post.title}</h3>
                <p className="mt-4 text-gray-600">{post.content.substring(0, 100)}...</p>
                <Link to={`/post/${post.id}`} className="text-blue-500 mt-2 inline-block">Read More</Link>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Blog;
