// compassion-frontend/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Blog from './components/Blog';
import ContactPage from './components/ContactPage';
import Footer from './components/Footer';
import Home from './components/Home';
import PostDetail from './components/BlogDetail';
import LoginPage from './components/LoginPage';
import AdminPanel from './components/AdminPanel';
import AdminPosts from './components/AdminPost';
import EditPost from './components/EditPost';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/admin/posts" element={<AdminPosts />} />
        <Route path="/contact" element={<ContactPage />} /> {/* Change to lowercase '/contact' */}
        <Route path="/post/:id" element={<PostDetail />} />
        <Route path="/admin/edit/:id" element={<EditPost />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin/*" element={<AdminPanel />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
