// compassion-frontend/src/components/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import missionImage from '../images/mission.jpg';
import Human from '../images/human.jpg'


const Home = () => {
    return (
        <div className="bg-gray-50 min-h-screen">

            {/* Hero Section */}
            <section className="relative bg-blue-600 text-white py-16 md:py-32 min-h-[90vh] flex items-center justify-center">
                <div className="absolute inset-0">
                    <img
                        src="https://images.unsplash.com/photo-1593642532973-d31b6557fa68"
                        alt="Hero Background"
                        className="object-cover w-full h-full opacity-30"
                    />
                </div>
                <div className="container mx-auto text-center px-6 relative z-10">
                    <h1 className="text-4xl md:text-5xl font-bold mb-4">Welcome to Code for Compassion</h1>
                    <p className="text-lg md:text-xl mb-6">
                        Empowering developers to create ethical software solutions that benefit humanity.
                    </p>
                    <Link to="/contact">
                        <button className="bg-white text-blue-600 font-semibold py-2 px-6 rounded-lg shadow-md relative overflow-hidden group">
                            <span className="relative z-10 text-blue-600 group-hover:text-white transition-colors duration-300">Get in Touch</span> {/* Text color change on hover */}
                            <span className="absolute inset-0 bg-blue-600 transform scale-0 group-hover:scale-125 transition-all duration-500 z-0"></span> {/* Expanding background */}
                        </button>
                    </Link>
                </div>
            </section>



            {/* About Us Section */}
            <section className="py-16 px-6 bg-white flex flex-col justify-center items-center">
                <h2 className="text-3xl font-semibold text-gray-800 mb-4">Our Mission</h2>
                <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center text-center lg:text-left space-y-8 lg:space-y-0 lg:space-x-12">

                    {/* Text Section */}
                    <div className="lg:w-1/2 w-full px-4">

                        <p className="text-gray-600 text-lg mb-6">
                            At Code for Compassion, our goal is to train developers to create ethical software that has a positive societal impact.
                        </p>
                        <p className="text-gray-600 text-lg mb-6">
                            In today’s fast-paced tech world, software development has the power to shape not only businesses but entire societies. With this power comes the responsibility to create solutions that prioritize ethical considerations, social good, and long-term sustainability. Our mission is to inspire and equip developers with the tools and knowledge to make ethically-informed decisions when creating software that impacts lives across the globe.
                        </p>
                        <p className="text-gray-600 text-lg mb-6">
                            We are committed to building solutions that are responsible, inclusive, and sustainable for the long term. Our programs and initiatives focus on promoting transparency, fairness, and inclusivity in every stage of the development lifecycle. We collaborate with developers, nonprofits, and community leaders to ensure that every software solution we contribute to serves humanity in the most compassionate and beneficial way possible.
                        </p>
                    </div>

                    {/* Image Section with Gap */}
                    <div className="lg:w-1/2 w-full relative lg:ml-12">
                        <img
                            src={missionImage} // Use the imported image here
                            alt="Mission Image"
                            className="w-full h-auto object-cover rounded-2xl shadow-lg"
                        />
                        <div className="absolute inset-0 bg-black opacity-30"></div>
                        <div className="absolute inset-0 flex items-center justify-center z-10">
                            <h3 className="text-2xl font-bold text-white">Building a Better Future Together</h3>
                        </div>
                    </div>
                </div>

                {/* Additional Text for Emphasis */}
                <div className="max-w-3xl mx-auto mt-12 text-center px-4">
                    <p className="text-gray-600 text-lg font-medium">
                        Through our innovative training programs, open-source contributions, and steadfast commitment to transparency, we aspire to create an enduring impact that reverberates far beyond the confines of our immediate community. By uniting developers, organizations, and visionaries, we are driving the transformation of technology into a powerful force for good—a force that upholds ethics, inclusivity, and social responsibility at its core, shaping a future where every technological advancement benefits humanity as a whole.
                    </p>
                </div>
            </section>



            {/* Features Section with Images */}
            <section className="bg-gray-100 py-16 px-6">
                <div className="container mx-auto text-center">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-12">What We Offer</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        {/* Feature 1 */}
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <img
                                src="https://images.unsplash.com/photo-1593642532973-d31b6557fa68"
                                alt="Training"
                                className="w-full h-48 object-cover rounded-md mb-4"
                            />
                            <h3 className="text-xl font-semibold text-blue-600 mb-4">Training Ethical Developers</h3>
                            <p className="text-gray-600 mb-4">We provide resources and courses to train developers in ethical software practices.</p>
                        </div>

                        {/* Feature 2 */}
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <img
                                src={Human}
                                alt="Solutions"
                                className="w-full h-48 object-cover rounded-md mb-4"
                            />
                            <h3 className="text-xl font-semibold text-blue-600 mb-4">Humanitarian-Focused Solutions</h3>
                            <p className="text-gray-600 mb-4">Our solutions tackle global challenges in healthcare, education, and more.</p>
                        </div>



                        {/* Feature 3 */}
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <img
                                src="https://images.unsplash.com/photo-1521747116042-5a810fda9664"
                                alt="Accountability"
                                className="w-full h-48 object-cover rounded-md mb-4"
                            />
                            <h3 className="text-xl font-semibold text-blue-600 mb-4">Oversight and Accountability</h3>
                            <p className="text-gray-600 mb-4">We ensure ethical oversight to prevent misuse and ensure fair software development.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Call to Action Section */}
            <section className="bg-blue-600 text-white py-16 md:py-32">
                <div className="container mx-auto text-center px-6">
                    <h2 className="text-3xl md:text-4xl font-semibold mb-6">Ready to Make a Difference?</h2>
                    <p className="text-lg md:text-xl mb-8">Join us today and start building ethical, impactful software for the future.</p>
                    <Link to="/contact">
                        <button className="bg-white text-blue-600 font-semibold py-3 px-8 rounded-lg shadow-md relative overflow-hidden group">
                            <span className="relative z-10 text-blue-600 group-hover:text-white transition-colors duration-300">Contact Us</span>
                            <span className="absolute inset-0 bg-blue-600 transform scale-0 group-hover:scale-125 transition-all duration-500 z-0"></span> {/* Expanding background */}
                        </button>
                    </Link>
                </div>
            </section>

        </div>
    );
};

export default Home;
