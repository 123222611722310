import React, { useState } from 'react';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    Institutionname: '',
    Profession: '',
    phoneNumber: '',
    message: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // Track if the form is submitted

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true); // Set submission status to true
    setFormData({
      name: '',
      Institutionname: '',
      Profession: '',
      phoneNumber: '',
      message: '',
    }); // Reset form fields
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-16">
      <h2 className="text-3xl font-semibold text-center text-gray-800">Contact Us</h2>

      <div className="mt-10 grid grid-cols-1 gap-8 md:grid-cols-2">
        {/* Contact Form */}
        <div className="bg-white p-8 shadow-lg rounded-lg">
          <h3 className="text-2xl font-medium text-gray-800 mb-4">Send Us a Message</h3>

          {/* Show confirmation message */}
          {isSubmitted && (
            <div className="mb-4 p-3 rounded-md bg-green-100 text-green-800">
              Your message has been submitted successfully!
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-600" htmlFor="name">
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-600" htmlFor="Institutionname">
                Institution Name
              </label>
              <input
                type="text"
                id="Institutionname"
                name="Institutionname"
                value={formData.Institutionname}
                onChange={handleChange}
                required
                className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-600" htmlFor="Profession">
                Profession
              </label>
              <input
                type="text"
                id="Profession"
                name="Profession"
                value={formData.Profession}
                onChange={handleChange}
                required
                className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-600" htmlFor="phoneNumber">
                Phone Number
              </label>
              <input
                type="number"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-gray-600" htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full mt-2 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows="4"
              />
            </div>
            <button
              type="submit"
              className="w-full mt-4 py-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300"
            >
              Send Message
            </button>
          </form>
        </div>

        {/* Contact Information */}
        <div className="bg-white p-8 shadow-lg rounded-lg">
          <h3 className="text-2xl font-medium text-gray-800 mb-4">Our Contact Information</h3>
          <p className="text-gray-600 mb-4">
            If you have any questions, feel free to reach out to us using the form or through the details below.
          </p>
          <div className="mb-4">
            <h4 className="font-semibold text-gray-800">Phone</h4>
            <p className="text-gray-600">+1 973 409 1097</p>
          </div>
          <div>
            <h4 className="font-semibold text-gray-800">Email</h4>
            <p className="text-gray-600">contact@codeforcompassion.org</p>
            <p className="text-gray-600">jkaur@codeforcompassion.org</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
