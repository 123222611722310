// compassion-frontend/src/components/Footer.js
import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';


const Footer = () => {
    return (
        <footer className="bg-gray-900 text-white py-8 mt-16">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* About Section */}
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Code for Compassion</h3>
                        <p className="text-gray-400">
                            We are committed to educating and empowering individuals to create ethical software solutions
                            that prioritize humanity, fairness, and societal good.
                        </p>
                    </div>

                    {/* Useful Links */}
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Useful Links</h3>
                        <ul className="space-y-2">
                            <li>
                                <a href="/" className="text-gray-400 hover:text-white transition duration-300">Home</a>
                            </li>
                            <li>
                                <a href="/examples" className="text-gray-400 hover:text-white transition duration-300">Examples</a>
                            </li>
                            <li>
                                <a href="/blog" className="text-gray-400 hover:text-white transition duration-300">Blog</a>
                            </li>
                            <li>
                                <a href="/contact" className="text-gray-400 hover:text-white transition duration-300">Contact</a>
                            </li>
                        </ul>
                    </div>

                    {/* Social Media */}
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
                        <div className="flex space-x-4">
                            <a
                                href="https://www.facebook.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-gray-400 hover:text-white transition duration-300"
                            >
                                <FaFacebook size={24} />
                            </a>
                            <a
                                href="https://www.twitter.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-gray-400 hover:text-white transition duration-300"
                            >
                                <FaTwitter size={24} />
                            </a>
                            <a
                                href="https://www.linkedin.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-gray-400 hover:text-white transition duration-300"
                            >
                                <FaLinkedin size={24} />
                            </a>

                        </div>
                    </div>
                </div>

                {/* Bottom Section */}
                <div className="border-t border-gray-700 mt-8 pt-6 text-center">
                    <p className="text-gray-400 text-sm">
                        &copy; {new Date().getFullYear()} Code for Compassion. All Rights Reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
