// AdminPosts.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './sidebar'; // Import Sidebar component
import BASE_URL from '../constant';

const AdminPosts = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    // Fetching blog posts from the API endpoint
    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            setErrorMessage('You are not logged in. Redirecting...');
            setTimeout(() => window.location.href = '/login', 3000); // Redirect to login if no token
            return;
        }

        axios
            .get(BASE_URL + '/blogposts/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setPosts(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching blog data:', error.response?.data || error.message);
                setErrorMessage('Failed to load blogs. Please try again.');
                setLoading(false);
            });
    }, []);

    // Handle deletion of a post
    const handleDelete = (id) => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            setErrorMessage('Authentication token is missing.');
            return;
        }

        axios
            .delete(`${BASE_URL}/blogposts/${id}/delete/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                setPosts(posts.filter((post) => post.id !== id));
            })
            .catch((error) => {
                console.error('Error deleting post:', error);
                setErrorMessage('Failed to delete the post. Please try again.');
            });
    };

    return (
        <div className="flex">
            {/* Sidebar */}
            <Sidebar /> {/* Use the Sidebar component here */}

            {/* Content Area */}
            <div className="flex-1 p-8">
                <h1 className="text-3xl font-bold text-center mb-8">All Blog Posts</h1>

                {errorMessage && (
                    <div className="bg-red-100 text-red-700 p-4 rounded-md mb-6">
                        {errorMessage}
                    </div>
                )}

                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {posts.length === 0 ? (
                            <p>No posts available.</p>
                        ) : (
                            posts.map((post) => (
                                <div key={post.id} className="bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden">
                                    {post.image && (
                                        <img src={post.image} alt={post.title} className="w-full h-56 object-cover" />
                                    )}
                                    <div className="p-6">
                                        <h3 className="text-xl font-semibold text-gray-800">{post.title}</h3>
                                        <p className="mt-4 text-gray-600">{post.content.substring(0, 100)}...</p>
                                        <div className="mt-4">
                                            <Link to={`/admin/edit/${post.id}`} className="text-blue-500 mr-4">Edit</Link>
                                            <button
                                                onClick={() => handleDelete(post.id)}
                                                className="text-red-500"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminPosts;
