import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="bg-blue-800 w-64 text-white p-4 flex flex-col space-y-6 hidden lg:block">
      <h2 className="text-2xl font-bold">Admin Panel</h2>
      <nav>
        <ul>
          <li>
            <Link to="/admin/dashboard" className="block py-2 px-4 rounded-md hover:bg-blue-600">
              Dashboard
            </Link>
          </li>
          <li>
            <Link to="/admin/posts" className="block py-2 px-4 rounded-md hover:bg-blue-600">
              Blog Posts
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
