import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from './sidebar'; // Import the Sidebar component
import BASE_URL from '../constant';

const AdminPanel = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    // Fetching blog data
    useEffect(() => {
        const token = localStorage.getItem('auth_token');
        if (!token) {
            console.error("Authentication token is missing.");
            setErrorMessage('You are not logged in. Redirecting...');
            setTimeout(() => navigate('/login'), 3000);
            return;
        }

        axios
            .get(BASE_URL + '/blogposts/', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setBlogs(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching blog data:', error.response?.data || error.message);
                setErrorMessage('Failed to load blogs. Please try again.');
                setLoading(false);
            });
    }, [navigate]);

    // Handle form submission to create a new blog
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!title || !content) {
            setErrorMessage("Title and Content are required.");
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        if (image) {
            formData.append('image', image);
        } else {
            setErrorMessage("Image is required.");
            return;
        }

        const token = localStorage.getItem('auth_token');
        if (!token) {
            setErrorMessage('Authentication token is missing.');
            return;
        }

        axios
            .post(BASE_URL + '/blogposts/create', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                setBlogs([response.data, ...blogs]);
                setTitle('');
                setContent('');
                setImage(null);
                setErrorMessage('');
                console.log(response.data)
            })
            .catch((error) => {
                console.error('Error creating blog post:', error.response?.data || error.message);
                setErrorMessage('Failed to create blog. Please try again.');
            });
    };


    return (
        <div className="flex min-h-screen bg-gray-200">
            {/* Sidebar */}
            <Sidebar /> {/* Using the Sidebar component here */}

            {/* Main Content */}
            <div className="flex-1 p-6">
                <h1 className="text-3xl font-semibold mb-4">Dashboard</h1>

                {/* Display error message */}
                {errorMessage && (
                    <div className="bg-red-100 text-red-700 p-4 rounded-md mb-6">
                        {errorMessage}
                    </div>
                )}

                {/* Create New Blog */}
                <div className="bg-white p-6 shadow-lg rounded-md mb-6">
                    <h3 className="text-xl font-semibold mb-4">Create New Blog</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                Title
                            </label>
                            <input
                                type="text"
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="w-full p-2 border rounded-md mt-2"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                                Content
                            </label>
                            <textarea
                                id="content"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                className="w-full p-2 border rounded-md mt-2"
                                required
                            ></textarea>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="image" className="block text-sm font-medium text-gray-700">
                                Image
                            </label>
                            <input
                                type="file"
                                name='imaage'
                                id="image"
                                onChange={(e) => setImage(e.target.files[0])}
                                className="mt-2"
                            />
                        </div>
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-500"
                        >
                            Create Blog
                        </button>
                    </form>
                </div>

                {/* Dashboard stats */}
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {/* Total Blogs */}
                        <div className="bg-white p-4 shadow-lg rounded-md">
                            <h3 className="text-xl font-semibold">Total Blogs</h3>
                            <p className="text-2xl">{blogs.length}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminPanel;
