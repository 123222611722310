// compassion-frontend/src/components/Navbar.js
import React from 'react';

const Navbar = () => {
  return (
    <nav className="bg-gray-800 shadow-lg sticky top-0 z-50 p-4">
      <div className="container mx-auto flex justify-between items-center">

        {/* Logo or Brand Name */}
        <h1 className="text-white font-bold text-2xl">Code for Compassion</h1>

        {/* Navbar Links */}
        <div className="space-x-6 hidden md:flex">
          <a
            href="/"
            className="text-gray-300 font-medium hover:text-blue-400 transition duration-300 hover:border-b-2 border-blue-400"
          >
            Home
          </a>

          <a
            href="/blog"
            className="text-gray-300 font-medium hover:text-blue-400 transition duration-300 hover:border-b-2 border-blue-400"
          >
            Blog
          </a>
          <a
            href="/contact"
            className="text-gray-300 font-medium hover:text-blue-400 transition duration-300 hover:border-b-2 border-blue-400"
          >
            Contact
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
